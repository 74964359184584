import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "./SectionYourShoes.scss"

class SectionYourShoes extends React.Component {

    render() {
        return (

            <div className="sectionYourShoes">
                <div className="yourShoesHeader">
                    <h2>I have been in your shoes</h2>
                </div>

                <div className="yourShoesBody">
                    <p>I've experienced the dreaded 2:00 a.m. phone call when our website went down. Despite maintaining a 99.8% uptime, unforeseen events like Amazon outages and DDoS attacks can catch us off guard. That's why I created Page Patrol. It's a cutting-edge monitoring and alert system that ensures you're never left in the dark. With real-time notifications, you'll be the first to know about any disruptions, allowing you to take immediate action. Say goodbye to stressful surprises and stay ahead of the curve.</p>
                    <p><em>- Tom Hall, Founder</em></p>
                </div>

                <div className="yourShoesImage">
                    <StaticImage
                        className="headshot"
                        src="../../assets/images/page-images/thomas.jpg"
                        alt="Thomas Hall"
                        placeholder="blurred"
                        layout="fixed"
                        width={200}
                        height={200}
                        quality={100}
                    />


                </div>

            </div>

        )
    }
}



export default SectionYourShoes