import React from "react"
import Faq from "./faq";
import "./SectionFaq.scss"


class SectionFaq extends React.Component {

    render() {
        return (

            <div className="sectionFaq">
                <div className="faqHeader">
                    <h2>FAQs</h2>
                </div>

                <div className="faqs">

                    <Faq>
                        <Faq.Question>
                            How often do you check my site?
                        </Faq.Question>
                        <Faq.Answer>
                            We check every site each hour.  If you need it checked more frequently than this, let us know at hello@page-patrol.com.
                        </Faq.Answer>
                    </Faq>

                    <Faq>
                        <Faq.Question>
                            How do you alert me if something is wrong
                        </Faq.Question>
                        <Faq.Answer>
                            We check every site each hour.  If you need it checked more frequently than this, let us know at hello@page-patrol.com.
                        </Faq.Answer>
                    </Faq>

                    <Faq>
                        <Faq.Question>
                            How do I know you are checking my site?
                        </Faq.Question>
                        <Faq.Answer>
                            Every Monday, we will send you a summary of the previous week.  This will include the weekly, monthly, and yearly uptime.
                        </Faq.Answer>
                    </Faq>



                    <Faq>
                        <Faq.Question>
                            How much does it cost?
                        </Faq.Question>
                        <Faq.Answer>
                            $0.99 per month per webpage.  That's it.
                        </Faq.Answer>
                    </Faq>


                    <Faq>
                        <Faq.Question>
                            Can you check multiple pages within a site?
                        </Faq.Question>
                        <Faq.Answer>
                            Sure thing.  Just email us to let us know what you want. It's only $0.99 per page.
                        </Faq.Answer>
                    </Faq>


                    <Faq>
                        <Faq.Question>
                            Can you check multiple sites?
                        </Faq.Question>
                        <Faq.Answer>
                            Yep.  If you manage several sites, we can handle it all.
                        </Faq.Answer>
                    </Faq>


                    <Faq>
                        <Faq.Question>
                            What exactly do you look for in determining the site is up?
                        </Faq.Question>
                        <Faq.Answer>
                            When a webpage loads, it passes a status code to the browser to let you know if there are errors.  Specifically, we are looking for a 200 code, which means that the site is working.
                        </Faq.Answer>
                    </Faq>


                    <Faq>
                        <Faq.Question>
                            Can I monitor a competitor's site?
                        </Faq.Question>
                        <Faq.Answer>
                            We can monitor any public facing site.
                        </Faq.Answer>
                    </Faq>


                    <Faq>
                        <Faq.Question>
                            Will this mess up my analytics?
                        </Faq.Question>
                        <Faq.Answer>
                            If you use Google Analytics, this will not show up.  We don't load any of the javascript on your site, which would trigger Google Analytics.
                        </Faq.Answer>
                    </Faq>


                    <Faq>
                        <Faq.Question>
                            How do I cancel?
                        </Faq.Question>
                        <Faq.Answer>
                            Give us a shout at hello@page-patrol.com.  We are working on making it simpler.
                        </Faq.Answer>
                    </Faq>

                </div>


            </div>

        )
    }
}



export default SectionFaq