import React from "react"
import "./sectionBillboard1.scss"
import { StaticImage } from "gatsby-plugin-image"


class Clock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            time: new Date().toLocaleTimeString()
        };
    }

    componentDidMount() {
        this.intervalID = setInterval(
            () => this.tick(),
            1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    tick() {
        this.setState({
            time: new Date().toLocaleTimeString()
        });
    }

    render() {
        return (
            <span>{this.state.time}</span>
        );
    }
}


class SectionBillboard1 extends React.Component {

        render() {
        return (

            <div className="SectionBillboard1">
                <div className="copyBillboard">
                    <h1> It's <Clock /><br/>Is Your Website Up?</h1>
                    <h2>Ensure Maximum Uptime for Your Website: Get Instant SMS & Email Alerts When it Goes Down!</h2>
                </div>

                <div className="imageBillboard">
                    <StaticImage
                        className="imgClock"
                        src="../../assets/images/page-images/clock.png"
                        alt="Clock"
                        placeholder="blurred"
                        layout="constrained"
                        maxWidth={400}
                        quality={100}
                    />
                </div>


            </div>

        )
    }
}

export default SectionBillboard1






