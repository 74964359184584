import React from "react"
import { Script } from "gatsby"

import shield from "../../assets/images/page-images/shield.svg"

import "./SectionCta1.scss"

class SectionCta1 extends React.Component {

    render() {
        return (

            <div className="sectionCta1">
                <div className="cta1Body">
                    <Script
                        async
                        src="https://js.stripe.com/v3/buy-button.js">
                    </Script>
                    <stripe-buy-button
                        buy-button-id="buy_btn_1N9IqdFT48oOj6nKr92HILew"
                        publishable-key="pk_live_51N9IR9FT48oOj6nKUEhGEkGtDdMDUBXmvv0h8CU5S6DDudXvmSkjgco9CvuQILdWh3wNCgufg9MLj83g6c1wqMU700hd1WaLGb"
                    >
                    </stripe-buy-button>

                </div>


                <div className="cta1Image">

                    <img src={shield} alt="Page Patrol Shield" />


                </div>

            </div>

        )
    }
}



export default SectionCta1