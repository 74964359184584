import React from "react"
import { Helmet } from "react-helmet"
import LayoutMain from "../components/layouts/LayoutMain"
import "../styles/homepage.scss"
import SectionBillboard1 from "../components/sectionBillboard1/SectionBillboard1";
import SectionCta1 from "../components/sectionCta1/SectionCta1";
import SectionFaq from "../components/sectionFaq/SectionFaq";
import SectionYourShoes from "../components/sectionYourShoes/SectionYourShoes";






class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showheadline:1
    }
  }


  componentDidMount() {
    this.interval = setInterval(() => this.setState({ showheadline: (this.state.showheadline===3 ? 1 : this.state.showheadline + 1) }), 5000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }



  render() { 
    return (
      <LayoutMain>
        <Helmet>
          <title>Page Patrol</title>
          <meta
            name="description"
            content="Page Patrol provides SMS & Email alerts if your website is down."
          />
          <bodyAttributes
            className="homepage"
          />
        </Helmet>

        <div className="container-homepage">



          <div className="containerSectionBillboard1">
            <SectionBillboard1/>
          </div>

          <div className="containerSectionCta1">
            <SectionCta1/>
          </div>


          <div className="containerSectionYourShoes">
            <SectionYourShoes/>
          </div>



          <div className="containerSectionFaq">
            <SectionFaq/>
          </div>

          <div className="containerSectionCta1">
            <SectionCta1/>
          </div>

        </div>
      </LayoutMain>
    )
  }
}

export default IndexPage
