import React from "react"

class Faq extends React.Component {

    render() {
        const { children } = this.props;

        return (
            <div className="faq">
                {React.Children.map(children, (child) => child)}
            </div>
        )
    }
}

Faq.Question = ({ children }) => <div className="faqQuestion"><h3>{children}</h3></div>;
Faq.Answer = ({ children }) => <div className="faqAnswer"><p>{children}</p></div>;



export default Faq